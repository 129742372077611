//Bootstrap Toggle Options

$enable-flex:               true ;
$enable-rounded:            false ;
$enable-shadows:            false ;
$enable-gradients:          false ;
$enable-transitions:        false ;
$enable-hover-media-query:  false ;
$enable-grid-classes:       true ;
$enable-caret:              false;
$enable-print-styles:       true ;

//Link Style Defaults
$link-hover-decoration:         none;

$theme-colors: (
        "primary": #3d7096,
        "secondary" : #2B8E94,
        "success" : #0ec1a9,
        "info" : #ea89ca,
        "warning" :  #FDC300,
        "danger" : #c50026,
        "light" : #f1f1f1,
        "dark" : #2A3940
);

//Override Bootstrap breakpoints
$grid-breakpoints: (
        xxs: 0,
        xs: 400px,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1600px
);

//Override Bootstrap container sizes
$container-max-widths: (
        md: 680 * (1px),
        lg: 900 * (1px),
        xl: 1000 * (1px),
        xxl: 1200 * (1px)
);

////Add extra spacer categories
$spacer : 1rem;
$spacers: (
        6: ($spacer * 4.5)
);